<template>
  <div class="spinner">
    <span v-if="showText" class="spinner-text">{{ text }}</span>
    <v-progress-circular
      indeterminate
      color="primary"
      style="width:100%;"
    ></v-progress-circular>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex'
export default {
  name: 'Spinner',
  props: {
    text: {
      type: String,
      default: 'Carregando ...',
      required: false
    },
    showText: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.spinner {
  margin: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  .spinner-text {
    padding: 10px 0;
  }
}
</style>

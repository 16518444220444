<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <div>
      <Panel
        v-if="isLoading"
        lineColor="var(--primary-color)"
        style="margin-bottom: 0px; display: flex;"
      >
        <Spinner />
      </Panel>

      <Grid grid-template-columns="auto 300px" v-if="!isLoading">
        <div>
          <Card lineColor="var(--primary-color)">
            <a href="https://lifeapps.com.br/" target="_blank">
              <img
                src="@/assets/mandala_omnichannel.png"
                style="width: 35%; height: 100%;"
              />
            </a>
          </Card>
          <Card
            style="margin: 15px;"
            lineColor="var(--primary-color)"
            v-for="(d, i) in diagnoses"
            :key="i"
            :ref="d.iddiagnostico"
          >
            <v-list shaped>
              <v-subheader style="height: 20px;margin-bottom: 10px;">
                <h3>{{ d.nome }}</h3>
              </v-subheader>
              <v-list-item-group>
                <v-list-item
                  v-for="(etapa, y) in d.etapas"
                  :key="y"
                  style="min-height: 0;"
                >
                  <v-list-item-content
                    style="text-align: left;"
                    class="item-slim"
                  >
                    <span style="font-size:0.9rem;">{{ etapa.nome }}</span>
                    <span style="font-size:0.8rem; color:gray;">{{
                      etapa.descricao
                    }}</span>
                  </v-list-item-content>
                  <a
                    style="font-size:0.9rem;text-decoration: unset;"
                    v-if="etapa.link_ajuda"
                    :href="etapa.link_ajuda"
                    target="_blank"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">far fa-question-circle</v-icon>
                      </template>
                      Veja como usar no MeAjuda
                    </v-tooltip>
                  </a>
                  <v-list-item-action class="item-slim status">
                    <div>
                      <div
                        v-if="etapa.passou"
                        style="padding:5px; font-size: 1.5rem;"
                      >
                        <i style="color: green" class="fas fa-check"></i>
                      </div>
                      <div v-else style="padding:5px; font-size: 1.5rem;">
                        <i style="color: gray" class="fas fa-minus"></i>
                      </div>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </Card>
        </div>
        <Panel style="font-size: .9rem;text-align: left;">
          <div v-for="(d, i) in diagnoses" :key="i">
            <Grid grid-template-columns="auto 50px">
              <button
                @click="scrollTo(d.iddiagnostico)"
                style="color:#1976d2;text-align: left;"
              >
                <span style="flex:1;">{{ d.nome }}</span>
              </button>
              <v-progress-linear
                color="light-green"
                height="10"
                :value="d.porcentagemFinal"
                striped
              >
                <span style="    font-size: 10px;"
                  >{{ d.porcentagemFinal | formatPrecision(4) }} %</span
                >
              </v-progress-linear>
            </Grid>
          </div>
        </Panel>
      </Grid>
    </div>
  </div>
</template>

<script>
import { DIAGNOSTICO_LOAD } from '@/store/actions/diagnostico'
import Panel from '@/components/Panel'
import Card from '@/components/Card'
import Grid from '@/components/Grid'
import Spinner from '@/components/Spinner'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'inicio',
  components: {
    Spinner,
    Panel,
    Grid,
    Card,
    MarketingModules
  },
  data: () => ({
    diagnoses: [],
    totalConclusao: 0,
    loading: { diagnoses: true },
    active: null,
    tabs: [
      {
        key: 'inicio',
        text: 'Início'
      },
      {
        key: 'diagnosticos',
        text: 'Diagnósticos'
      }
    ],
    tabStart: 0,
    modulosForm: [ModulesEnum.TODOS]
  }),
  created() {
    this.getDignoses()
  },
  computed: {
    ...mapGetters([
      'getEmpresaId',
      'getFornecedorId',
      'getFornecedorTag',
      'moduloFornecedorAtivo'
    ]),
    endpoint() {
      return `/api/v4/report/datatable/${this.getFornecedorId}`
    },
    isLoading() {
      return this.loading.diagnoses
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    getDignoses() {
      this.$store
        .dispatch(DIAGNOSTICO_LOAD)
        .then(result => {
          if (result) {
            this.diagnoses = result.data
            this.totalConclusao = result.totalConclusao
          }
          this.loading.diagnoses = false
        })
        .catch(() => {
          this.loading.diagnoses = false
        })
    },
    scrollTo(id) {
      const el = this.$refs[id]
      const element = _.get(el, '0.$el')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss">
.item-slim {
  margin: 0;
  padding: 0;
  .status {
    width: 115px;
    max-width: 115px;
  }
}
</style>
